import React from 'react';
import './App.css';
import Footer from './Components/Footer/Footer';
import Hero from './Components/HeroSection/Hero';
import Join from './Components/Join/Join';
import Plan from './Components/Plans/Plan';
import Program from './Components/Program/Program';
import Reasons from './Components/Reasons/Reasons';
import Testimonials from './Components/Testimonials/Testimonials';

function App() {
  return (
    <div className="App">
      <Hero />
      <Program />
      <Reasons />
      <Plan />
      <Testimonials />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
