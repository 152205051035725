import React from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll';
import { useState } from 'react'
const Header = () => {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOpen, setmenuOpen] = useState(false)
    return (
        <div className='header'>
            <img src={Logo} alt='' className='logo' />
            {(menuOpen === false && mobile === true) ? (

                <div style={{ backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px' }}>
                    <img src={Bars} alt='' style={{ width: '1.5rem', height: '1.5rem' }}
                        onClick={() => setmenuOpen(true)}
                    />

                </div>
            ) : <ul className='header-menu'>
                <li><Link onClick={() => setmenuOpen(false)} to='home' spy={true} smooth={true} >Home</Link></li>
                <li><Link onClick={() => setmenuOpen(false)} to='program' spy={true} smooth={true}>Programs</Link></li>
                <li><Link onClick={() => setmenuOpen(false)} to='reasons' spy={true} smooth={true}>Why Us</Link></li>
                <li><Link onClick={() => setmenuOpen(false)} to='page' spy={true} smooth={true}>Pages</Link></li>
                <li><Link onClick={() => setmenuOpen(false)} to='testimonials' spy={true} smooth={true}>Testimonials</Link></li>
            </ul>}

        </div>
    )
}

export default Header