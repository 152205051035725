import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status: 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Fitclub is a very apt place to workout and change our mood. A crew of dedicated fitness professionals and their friendly training approach. I am happy to sculpt my body in FitClub along with my trainers and awesome state of the art machines',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image: image3,
    review: 'Thank you for helping me get the most out of the limited time I have at the gym. I would higly recommend everyone to join "Fitclub" to chase your goal for perfect body. The trainers and staffs are well disciplined and highly qualified ',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
